<template>
  <AppValidation
    :rules="rules"
    :name="$attrs.name || $attrs.label"
    v-slot="{ errors }"
  >
    <v-text-field
      v-model="innerValue"
      v-bind="$attrs"
      :error-messages="errors"
    ></v-text-field>
  </AppValidation>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    'AppValidation': ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    value: {
      type: null
    },
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>
