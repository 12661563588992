<template>
  <v-toolbar flat>
    <!-- web -->
    <v-btn
      v-if="!disabledAct"
      color="primary"
      depressed
      small
      class="d-none d-sm-flex"
      @click="onClick"
      v-shortkey="['ctrl', 'm']"
      @shortkey="onClick"
    >
      {{ label }} <v-icon right>mdi-plus</v-icon>
    </v-btn>
    <!-- mobile -->
    <v-btn
      v-if="!disabledAct"
      color="primary"
      fab
      x-small
      depressed
      class="hidden-sm-and-up"
      @click="onClick"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-spacer class="mx-1"></v-spacer>
    <v-text-field
      v-model="temp"
      append-icon="mdi-magnify"
      label="Search"
      name="search"
      filled dense
      single-line
      hide-details
    ></v-text-field>
  </v-toolbar>
</template>

<script>
export default {
  props: [ 'label', 'searchVal', 'disabledAct' ],
  computed: {
    temp: {
      get: function() {
        return this.searchVal
      },
      set: function(newVal) {
        this.$emit('onEmitSearch', newVal)
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('onEmitClick')
    },
  }
}
</script>