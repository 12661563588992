






































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { debounce } from 'underscore'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'
import BSelect from '@/components/validation/BSelect.vue'
import DialogDelete from '@/components/DialogDelete.vue'
import Toolbar from '@/components/CRUD/Toolbar.vue'

@Component({
  components: {
    'AppForm': ValidationObserver,
    'AppInput': BInput,
    'AppSelect': BSelect,
    'AppDialogDelete': DialogDelete,
    'AppToolbar': Toolbar,
  }
})
export default class TrackingProduct extends Vue {
  //#region COMPUTED
  get name() {
    return this.editedItem ? this.editedItem.itemName : ''
  }
  get loading() {
    return this.$store.getters.loading
  }
  
  get dataItems() {
    return this.$store.getters['master/trackingProduct/data']
  }
  get items() {
    return this.dataItems.data
  }
  get totalItems() {
    return this.dataItems.totalRecords
  }
  get options() {
    return this.$store.getters['master/trackingProduct/options']
  }
  set options(newVal) {
    this.$store.dispatch('master/trackingProduct/OnOptions', newVal)
  }
  get search() {
    return this.$store.getters['master/trackingProduct/search']
  }
  set search(newVal) {
    this.$store.dispatch('master/trackingProduct/OnSearch', newVal)
    this.debouncedGetAnswer()
  }

  get formTitle():string {
    return this.editedIndex === -1 ? 'Produk Baru' : 'Edit Produk'
  }
  //#endregion
  //#region DATA
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>,
  }
  debouncedGetAnswer:any = null
  headers:Array<object> = [
    { text: 'Barcode', align: 'start', value: 'barcode' },
    { text: 'Kode', value: 'itemCode' },
    { text: 'Nama Produk', value: 'itemName' },
    { text: 'Unit', value: 'unit' },
    { text: 'Aktif', value: 'disabled' },
    { text: 'Act', align: 'center', value: 'actions', sortable: false },
  ]
  footers:object = { itemsPerPageOptions: [ 25, 50, 100 ] }
  dialog:boolean = false
  dialogDelete:boolean = false

  editedIndex:number = -1
  defaultItem:any = {
    barcode: null,
    itemCode: null,
    itemName: null,
    unit: null,
    disabled: false,
  }
  editedItem:any = {
    barcode: null,
    itemCode: null,
    itemName: null,
    unit: null,
    disabled: false,
  }
  //#endregion
  //#region WATCH
  @Watch('dialog')
  dialogChanged(val:boolean) {
    val || this.close()
  }
  @Watch('dialogDelete')
  dialogDeleteChanged(val:boolean) {
    val || this.close()
  }
  //#endregion
  //#region METHODS
  onSearch(value:string) {
    this.search = value
  }
  initialize() {
    if (this.options.page !== 1) this.options.page = 1
    this.$store.dispatch('master/trackingProduct/ReadData')
  }
  editItem(item:any, act:string) {
    this.editedIndex = this.items.indexOf(item)
    this.editedItem = Object.assign({}, item)

    if (act === 'edit') this.dialog = true
    else if (act === 'delete') this.dialogDelete = true
  }
  disabledItem(item:any) {
    this.$store.dispatch('master/trackingProduct/UpdateData', item)
    this.close()
  }
  confirmDeleteItem(val:boolean) {
    if (val) this.$store.dispatch('master/trackingProduct/DeleteData', this.editedItem)
    this.dialogDelete = false
  }
  saveItem() {
    this.$refs.observer.validate()
      .then(success => {
        if (!success) return
        
        if (this.editedIndex > -1) this.$store.dispatch('master/trackingProduct/UpdateData', this.editedItem)
        else this.$store.dispatch('master/trackingProduct/CreateData', this.editedItem)
        
        this.close()
      })
  }
  close() {
    this.dialog = false
    this.dialogDelete = false
    this.$nextTick(() => {
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      const resetForm = this.$refs.observer
      if (resetForm) resetForm.reset()
    })
  }
  //#endregion
  //#region CREATED
  //#endregion
  //#region MOUNTED
  mounted() {
    this.debouncedGetAnswer = debounce(this.initialize, 500)
  }
  //#endregion
}
