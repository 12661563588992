




















import Vue from 'vue'
export default Vue.extend({
  props: [ 'dialog', 'name' ],
  methods: {
    confirm(val:boolean) {
      this.$emit('emitOnConfirm', val)
    }
  }
})
